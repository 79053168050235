html,
body {
  height: 100%;
}

body {
  background-color: var(--innovigo-background-00);
  margin: 0;
  font-family: 'InnovigoMain', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@font-face {
  font-family: 'InnovigoMain';
  src: url('../public/fonts/Gilroy-Regular.otf');
}

@font-face {
  font-family: 'InnovigoMain';
  font-weight: bold;
  src: url('../public/fonts/Gilroy-Bold.otf');
}

@font-face {
  font-family: 'Material Icons';
  src: url('../public/fonts/MaterialIcons.ttf');
}
